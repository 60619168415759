import { StructureTypes, TaskTypes } from '@utils/Training'

//var videoId = 76979871;
var videoType = 'youtube';
//var videoId = 105077502;
var videoId = "Mryj0JR7h54";
// var videoOptions = {
//     id: videoId,
//     autoplay: false,
//     controls: false
// }
var videoData = [
    {
        stopTimeSeconds: 7,
        courseStructureId: '347643-234234',
    },
    {
        stopTimeSeconds: 25,
        courseStructureId: 'sdfweye-56756',
    },
    {
        stopTimeSeconds: 30,
        courseStructureId: 'fghtryrt-hlkhliuu',
    }
]
var courseStructures = {
    '347643-234234': {
        id: '347643-234234',
        structureType: StructureTypes.TASK,
        structureTypeId: '347643-234234',
        structureRefData: {
            type: TaskTypes.SINGLESELECT,
            content: {
                htmlContent: '<p>Huomatessasi, että ihminen ei reagoi ravisteluusi, mitä teet?</p>',
            },
            data: '[{"id":1,"values":[{"content":"Juokse hakemaan apua","value":1},{"content":"Soita 112","value":2},{"content":"Tarkista pulssi","value":3}]}]',
            answers: [{"id": 1, "value": 2}]
        }
    },
    'sdfweye-56756': {
        id: 'sdfweye-56756',
        structureType: StructureTypes.TASK,
        structureTypeId: 'sdfweye-56756',
        structureRefData: {
            type: TaskTypes.SINGLESELECT,
            content: {
                htmlContent: '<p>Minkä asian tarkistat ensimmäiseksi?</p>',
            },
            data: '[{"id":1,"values":[{"content":"Hengityksen","value":1},{"content":"Mahdolliset ruhjeet","value":2},{"content":"Mahdollisen verenvuodon","value":3}]}]',
            answers: [{"id": 1, "value": 1}]
        }
    },
    'fghtryrt-hlkhliuu': {
        id: 'fghtryrt-hlkhliuu',
        structureType: StructureTypes.TASK,
        structureTypeId: 'fghtryrt-hlkhliuu',
        structureRefData: {
            type: TaskTypes.SINGLESELECT,
            content: {
                htmlContent: '<p>Mihin asentoon laitat tajuttoman?</p>',
            },
            data: '[{"id":1,"values":[{"content":"Selinmakuulle","value":1},{"content":"Istumaan","value":2},{"content":"Kylkiasentoon","value":3}]}]',
            answers: [{"id": 1, "value": 2}]
        }
    }

    // '1-32423423-34634': {
    //     id: '1-32423423-34634',
    //     structureType: StructureTypes.CONTENT,
    //     structureTypeId: '1-32423423-34634',
    //     structureRefData: {
    //         header: 'Testiotsikko',
    //         htmlContent: '<p>Oikein valituilla alkusammuttimilla ja määrätietoisella sekä ripeällä toiminnalla uhkaavaltakin tuntuvasta palosta voi selvitä pelkällä säikähdyksellä. Noin joka neljäs tulipalo sammutetaan alkusammuttimilla kokonaan tai rajoitetaan merkittävästi.</p><p>Yrityksen eri toimipisteissä on kattava valikoima alkusammutusvälineistöä; käsisammuttimia, sammutuspeitteitä ja pikapaloposteja. Tutustu välineistön sijaintiin työpisteessäsi ja ota selvää hätäpoistumisteistä, jotka on merkitty vihreällä poistumisopastekilvellä. Hätätilanteessa näistä ovista on päästävä aina poistumaan ilman avainta.</p><p>Huom! Epäsiistit työtilat (lattialle unohtuneet trasselit ym.) ja huolimaton tupakointi ovat yleisimpiä tulipalojen syttymissyitä työpaikolla. Pidäthän huolta siitä, että työtilasi on siisti ja muista, että tupakointi on sallittu vain merkityssä paikassa.</p>',
    //     }
    // },
    // '347643-234234': {
    //     id: '347643-234234',
    //     structureType: StructureTypes.TASK,
    //     structureTypeId: '347643-234234',
    //     structureRefData: {
    //         type: TaskTypes.ORDER_THE_LIST,
    //         content: {
    //             htmlContent: '<p>Oikein valituilla alkusammuttimilla</p>',
    //         },
    //         //data: '{"options": [{"id":1, "values":[{"content": "Pelasta vaarassa olevat ja estä lisäonnettomuudet", "value": 1},{"content": "Tee hätäilmoitus", "value": 2},{"content": "Anna tarvittava ensiapu", "value": 3},{"content": "Tee tilannearvio", "value": 4},{"content": "Opasta sairaskuljetus perille", "value": 5}]}], "answer": [{"id": 1, "value": [4,2,1,3,5]}]}'
    //         data: '[{"id":1, "values":[{"content": "Pelasta vaarassa olevat ja estä lisäonnettomuudet", "value": 1},{"content": "Tee hätäilmoitus", "value": 2},{"content": "Anna tarvittava ensiapu", "value": 3},{"content": "Tee tilannearvio", "value": 4},{"content": "Opasta sairaskuljetus perille", "value": 5}]}]',
    //         answers: [{"id": 1, "value": [4,2,1,3,5]}]
    //     }
    // }
}

export default function VideoData() {
    return {
        videoId,
        videoType,
        videoData,
        courseStructures
    }
}